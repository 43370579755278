<template>
  <!--  左侧导航栏-->
  <left-hand-navigation-pane />
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";

import LeftHandNavigationPane from "@/components/navigationBar/LeftHandNavigationPane.vue";
import {getUserInfo} from "@/api/client/yonghuxinxijiekou";
import {getNowFormatDate} from "@/utils/date";

const store = useStore();
store.commit("initState");

// 是否登录
if (store.getters.userInfo) {
  // 这里需要判断是否拥有配置 如果没有则需要设置默认值
  let { userSetting } = store.getters;
  if (!userSetting) {
    userSetting = {
      // 默认使用第一个
      modelIndex: 0,
      // 默认使用第一个
      shortcutIndex: 0,
      // 默认使用Name
      chatBotName: "聊天机器人",
      // 狗牌
      chatBotTag: "GPT",
      // 上下文
      memorySize: 4000,
      // 速率 ms
      outputRate: 10,
      // 写作模型
      writingModelIndex: 0,
      // 记忆行数
      rowSize: 4,
      // 前置预设词
      presetWordIssue: "",
      // 后置预设此
      presetWordAnswer: "",
      // 是否启用预设此
      isPreset: false,
      // 使用的头像
      chatBotAvatar: "",
    };
    store.commit("setUserSetting", userSetting);
  }
}

onMounted(async () => {
  let timeList = JSON.parse(localStorage.getItem('timeList') || "[]") || []
  let today = getNowFormatDate()
  let userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
  let time = timeList.find(v => v === today)
  if (!time){
    timeList.push(today)
    localStorage.setItem('timeList', JSON.stringify(timeList))
    const mixed  = JSON.parse(localStorage.getItem('freeTime') || "{}").mixed
    localStorage.setItem('freeTime', JSON.stringify({
      char: 1,
      horo: 1,
      mixed: mixed || 2
    }))
    localStorage.setItem('first', '1')
  }
  let uuid = localStorage.getItem('uuid') || "";
  if (!uuid) {
    uuid = uuidv4();
    localStorage.setItem('uuid', uuid)
  }
  if (userInfo.id) {
    try {
      const res = await getUserInfo();
      store.commit('setUserInfo', JSON.stringify(res.result))
    } catch (e) {
      console.log(e);
    }
  }
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@200..900&display=swap");
//@import url('https://fonts.google.com/noto/specimen/Noto+Serif+SC?query=noto+serif+sc');
.google-font {
  font-weight: 700;
  font-family: "Noto Serif SC", serif;
  font-optical-sizing: auto;
}

.primary-button {
  height: 40px !important;
  font-weight: 700;
  background-image: linear-gradient(to right, #996aff, #2e55e3);
  border: none !important;
  border-radius: 10px !important;
}
.w-1-3 {
  width: 510px;
}
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: var(--el-text-color-primary);
  font-family: SF;
  line-height: 1.6;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-table {
  border-radius: 8px;
}

.el-table thead {
  color: #565656 !important;
}

.el-scrollbar {
  font-size: 13px;
}

.el-table .cell {
  text-align: center;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.el-table .warning-row {
  --el-table-tr-bg-color: #f0f1f8 !important;
}

.el-table .success-row {
  --el-table-tr-bg-color: #fdf6ec !important;
}

.el-loading-spinner .path {
  stroke: #a143ff !important;
}

.el-loading-spinner .el-loading-text {
  padding-top: 10px;
  color: #949494 !important;
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  display: block;
  background: #F2F2F;
  border-radius: 0;
}

@font-face {
  font-family: SmileySans;
  src: url(@/assets/fonts/SmileySans.otf);
  font-display: swap;
}

@font-face {


}

html,
body {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

:deep(.el-overlay) {
  background-color: rgba(0, 0, 0, 0.89);
}

main {
  flex: 1;
  background-color: #f2f2f2;
}

* {
  box-sizing: border-box;
}

.dot_0,
.dot_1,
.dot_2,
.dot_3 {
  width: 15px;
  height: 15px;
  background: rgb(166, 129, 236);
  border-color: #f2f2f2;
  border-radius: 50%;
}

.dot_0 {
  animation: jumpT 1.3s -0.64s linear infinite;
}

.dot_1 {
  animation: jumpT 1.3s -0.32s linear infinite;
}

.dot_2 {
  animation: jumpT 1.3s -0.16s linear infinite;
}

.dot_3 {
  animation: jumpT 1.3s linear infinite;
}

@keyframes jumpT {
  0%,
  80%,
  100% {
    background-color: #f2f2f2;
    transform: scale(0);
  }

  40% {
    background-color: rgb(186, 156, 241);
    transform: scale(1);
  }
}

.el-dialog__title {
  color: white !important;
}

.el-dialog {
  box-shadow: none !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.el-dialog__header {
  height: 0px !important;
}

.el-dialog__footer {
  height: 0px !important;
}

.el-dialog__body {
  padding: 0 !important;
}
.el-drawer__header {
  margin-bottom: 0px !important;
}

.el-drawer__header {
  height: 0 !important;
}

.el-input__wrapper{
  background: #f7f7f7 !important;
  border-radius: 12px;
}
.el-popper__arrow {
  display: none
}


</style>
