import { createRouter, createWebHashHistory } from "vue-router";
/*

import AppFunction from "@/views/app/AppFunction.vue";
import ChatFunction from "@/views/chat/ChatFunction.vue";
import ConsoleFunction from "@/views/console/ConsoleFunction.vue";
import GptView from "@/views/console/view/gpt/GptView.vue";
import MicroView from "@/views/console/view/micro/MicroView.vue";
import OrdersView from "@/views/console/view/orders/OrdersView.vue";
import ProductView from "@/views/console/view/product/ProductView.vue";
import UserView from "@/views/console/view/user/UserView.vue";
import DrawingFunction from "@/views/drawing/DrawingFunction.vue";
import Function from "@/views/function/IntroduceFunction.vue";
import Login from "@/views/login/LoginFunction.vue";
import ProgramsFunction from "@/views/programs/ProgramsFunction.vue";
import RegisteredFunction from "@/views/registered/RegisteredFunction.vue";
import SettingFunction from "@/views/setting/SettingFunction.vue";
*/

const routes = [
  {
    path: "/",
    name: "chat-function",
    component:  () => import('@/views/chat/ChatFunction.vue'),
    meta: { title: "CHAT", keepAlive: true },
  },
  {
    path: "/drawing",
    name: "drawing-function",
    component: () => import('@/views/drawing/DrawingFunction.vue'),
    meta: { title: "DRAWING", keepAlive: true },
  },
  {
    path: "/function",
    name: "function",
    component: () => import('@/views/function/IntroduceFunction.vue'),
    meta: { title: "INTRODUCE", keepAlive: true },
  },
  {
    path: "/registered",
    name: "registered",
    component: () => import('@/views/registered/RegisteredFunction.vue'),
    meta: { title: "REGISTERED", keepAlive: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/views/login/LoginFunction.vue'),
    meta: { title: "LOGIN", keepAlive: true },
  },
  {
    path: "/download",
    name: "download",
    component: () => import('@/views/download/downloadFunction.vue'),
    meta: { title: "DOWNLOAD", keepAlive: true },
  },
  {
    path: "/app",
    name: "app-function",
    component: () => import('@/views/app/AppFunction.vue'),
    meta: { title: "APP", keepAlive: true },
  },
  {
    path: "/programs",
    name: "programs-function",
    component: () => import('@/views/programs/ProgramsFunction.vue'),
    meta: { title: "WRITING", keepAlive: true },
  },
  {
    path: "/setting",
    name: "setting-function",
    component: () => import('@/views/setting/SettingFunction.vue'),
    meta: { title: "SETTING", keepAlive: false },
  },
  {
    path: "/console",
    name: "console-function",
    component: () => import('@/views/console/ConsoleFunction.vue'),
    meta: { title: "CONSOLE", keepAlive: false },
  },
  {
    path: "/console/user",
    name: "console-user",
    component: () => import('@/views/console/view/user/UserView.vue'),
    meta: { title: "CONSOLE-USER", keepAlive: false },
  },
  {
    path: "/console/gpt",
    name: "console-gpt",
    component: () => import('@/views/console/view/gpt/GptView.vue'),
    meta: { title: "CONSOLE-GPT", keepAlive: false },
  },
  {
    path: "/console/orders",
    name: "console-orders",
    component: () => import('@/views/console/view/orders/OrdersView.vue'),
    meta: { title: "CONSOLE-ORDERS", keepAlive: false },
  },
  {
    path: "/console/micro",
    name: "console-micro",
    component: () => import('@/views/console/view/micro/MicroView.vue'),
    meta: { title: "CONSOLE-MICRO", keepAlive: false },
  },
  {
    path: "/console/product",
    name: "console-product",
    component: () => import('@/views/console/view/product/ProductView.vue'),
    meta: { title: "CONSOLE-PRODUCT", keepAlive: false },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// TODO 全局前置守卫
router.beforeEach(async (to) => {
  const { title } = to.meta;
  // TODO 设置浏览器Title
  document.title = `${title || ""}- TS GPT`;
});

export default router;
