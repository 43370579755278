import { createStore } from "vuex";
import $dailyTest from "@/utils/mitt";

export default createStore({
  state: {
    // 用户数据
    userInfo: {},
    // 用户设置
    userSetting: undefined,
    theme: undefined,
  },
  getters: {
    userInfo: (state) => JSON.parse(localStorage.getItem('userInfo')) || state.userInfo  ,
    userSetting: (state) => state.userSetting || {},
  },
  mutations: {
    logout(state) {
      state.userInfo = undefined;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
      $dailyTest.emit('userInfo');
    },
    setUserInfo(state, info) {
      state.userInfo = info;
      localStorage.setItem("userInfo", info);
      $dailyTest.emit('userInfo');
    },

    setUserSetting(state, info) {
      state.userSetting = info;
      localStorage.setItem("userSetting", JSON.stringify(info));
    },
    initState(state) {
      const token = localStorage.getItem("token");
      if (token) {
        const user = localStorage.getItem("userInfo");
        const setting = localStorage.getItem("userSetting");
        state.userInfo = JSON.parse(user);
        state.userSetting = JSON.parse(setting);
      }
    },
  },
  actions: {},
  modules: {},
});
