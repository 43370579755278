// @ts-ignore
/* eslint-disable */
import request from '@/utils/request/index';

/** 获取当前登录用户接口 GET /v1/passport/member */
export async function getUserInfo(options?: { [key: string]: any }) {
  return request<API.ResultMessageMember>('/v1/passport/member', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 注销账号 PUT /v1/passport/member/cancellation */
export async function cancellation(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.cancellationParams,
  options?: { [key: string]: any },
) {
  return request<API.ResultMessageMember>('/v1/passport/member/cancellation', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修改用户个人档案 PUT /v1/passport/member/editOwn */
export async function editOwn(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.editOwnParams,
  options?: { [key: string]: any },
) {
  return request<API.ResultMessage>('/v1/passport/member/editOwn', {
    method: 'PUT',
    params: {
      ...params,
      memberEditDTO: undefined,
      ...params['memberEditDTO'],
    },
    ...(options || {}),
  });
}

/** 注销接口 POST /v1/passport/member/logout */
export async function logout(options?: { [key: string]: any }) {
  return request<API.ResultMessageObject>('/v1/passport/member/logout', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 校验旧密码后修改密码 PUT /v1/passport/member/modifyPass */
export async function modifyPass(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.modifyPassParams,
  options?: { [key: string]: any },
) {
  return request<API.ResultMessageMember>('/v1/passport/member/modifyPass', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 刷新token GET /v1/passport/member/refresh/${param0} */
export async function refreshToken(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.refreshTokenParams,
  options?: { [key: string]: any },
) {
  const { refreshToken: param0, ...queryParams } = params;
  return request<API.ResultMessageObject>(`/v1/passport/member/refresh/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 注册用户 POST /v1/passport/member/register */
export async function register(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.registerParams,
  options?: { [key: string]: any },
) {
  return request<API.ResultMessageObject>('/v1/passport/member/register', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 通过邮件修改密码 POST /v1/passport/member/resetPasswordByEmail */
export async function resetPasswordByEmail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.resetPasswordByEmailParams,
  options?: { [key: string]: any },
) {
  return request<API.ResultMessageMember>('/v1/passport/member/resetPasswordByEmail', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查看角色拥有的tap菜单 GET /v1/passport/member/role */
export async function getRole(options?: { [key: string]: any }) {
  return request<API.ResultMessageListMenu>('/v1/passport/member/role', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 登录接口 POST /v1/passport/member/userLogin */
export async function userLogin(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.userLoginParams,
  options?: { [key: string]: any },
) {
  return request<API.ResultMessageToken>('/v1/passport/member/userLogin', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
