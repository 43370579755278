<script setup></script>

<template>
  <div class="rightContent">
    <RouterView v-slot="{ Component }">
      <KeepAlive>
        <component
          :is="Component"
          v-if="$route.meta.keepAlive"
          :key="$route.name"
        ></component>
      </KeepAlive>
      <component
        :is="Component"
        v-if="!$route.meta.keepAlive"
        :key="$route.name"
      ></component>
    </RouterView>
  </div>
</template>

<style scoped>
.rightContent {
  //padding-left:  60px;
}
</style>
