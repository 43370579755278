/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { rejects } from "assert";
import { MessagePlugin } from "tdesign-vue-next";
import { extend } from "umi-request";
import { v4 as uuidv4 } from "uuid";

// import { useRouter } from 'vue-router';
import router from "@/router";
import {ElMessage} from "element-plus";

const config = {
  // api: 'http://47.99.90.88:8889',
  api: "/client",
};

/**
 * 异常处理程序
 */
const errorHandler = (error) => {
  const { response } = error;
  if (response && response.status) {
    const errorText = error.data.message;
    const { status, url } = response;
    if (errorText)ElMessage.error(errorText)
  } else if (!response) {
    ElMessage.error('网络异常')
  }
  // return response
};

/**
 * 配置request请求时的默认参数
 */
const require = extend({
  errorHandler, // 默认错误处理
  // prefix: config.mockApi,
  prefix: config.api,
  timeout: 600000,
});
// request拦截器, 改变url 或 options
require.interceptors.request.use(
  (url, options) => {
    let uuid = localStorage.getItem('uuid');
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem('uuid', uuid)
    }
    const headers = localStorage.getItem("accessToken")
      ? {
          accessToken: `${localStorage.getItem("accessToken")}`,
          uuid: `${uuid}`,
        }
      : {
          uuid: `${uuid}`,
        };

    return {
      url,
      options: { ...options, headers },
    };
  },
  { global: false }
);

const key = "updatable";
// 克隆响应对象做解析处理
require.interceptors.response.use(async (response) => {
  try {
    const data = response;
    // @ts-ignore
    if (data && (data.status === 403 || data.code === 403)) {
      localStorage.setItem("accessToken", "");
      localStorage.setItem("userInfo", "{}");
      ElMessage.error("登录已过期，请重新登录");
      // window.__POWERED_BY_QIANKUN__ ? (window.location.href = '/#/') : router.replace('/user/login')
      // await router.push({ path: "/login" });
      return data;
    }
    return data;
  } catch (error) {
  }
  return response;
});

export default require;
